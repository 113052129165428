// @author: Ville Wilpola
// This file figures out whether a drone is on the no-fly-zone or not

export const NoFlyZone = (x, y, id, setDist) => {
  // Center of the no-fly-zone
  let centerY = Number(250000)
  let centerX = Number(250000)

  // Finding the distance from the center of the no-fly-zone
  // --> And changing it to meters by dividing by 1000
  const circleY = Math.pow(Number(y) - centerY, 2)
  const circleX = Math.pow(Number(x) - centerX, 2)
  const distance = Math.sqrt(circleX + circleY) / 1000

  if (distance <= 100) {

    return true
  } else {
    return false
  }
}
