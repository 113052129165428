import './App.scss'
import { useState, useEffect } from 'react'

import axios from 'axios'
import XMLParser from 'react-xml-parser'
import { Radar, ListView } from './components'
import { NoFlyZone } from './utils'

function App() {
  const [drones, setDrones] = useState([])
  const [pilotsInViolation, setPilotsInViolation] = useState([])

  // Check whether state has the pilot information
  const checkViolations = (x) => {
    return pilotsInViolation.some((item) => item.pilotId === x)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // Get drone data from the API
      // Using package.json proxy
      axios
        .get('https://assignments.reaktor.com/birdnest/drones', {
          'Content-Type': 'application/xml; charset=utf-8',
          'Access-Control-Allow-Origin': '*'
        })
        .then((res) => {
          const data = new XMLParser().parseFromString(res.data)
          setDrones(data.getElementsByTagName('drone'))
          for (let i = 0; data.children[1].children.length > i; i++) {
            let x = NoFlyZone(
              data.children[1].children[i].children[7].value,
              data.children[1].children[i].children[8].value,
              data.children
            )

            // If a drone is in the No-Fly-Zone
            // Fetch their information from the API
            if (x === true) {
              axios
                .get(
                  `/birdnest/pilots/${data.children[1].children[i].children[0].value}`,
                )
                .then((res) => {
                  // Get access to the response data
                  let d = res.data

                  // Check whether the pilot is violations in the past 10 minutes
                  if (!checkViolations(d.pilotId)) {
                    // If there are no previos violations, add them to the list
                    setPilotsInViolation([
                      ...pilotsInViolation,
                      {
                        timeViolated: new Date().toISOString(),
                        pilotId: d.pilotId,
                        createdAt: d.createdDt,
                        firstName: d.firstName,
                        lastName: d.lastName,
                        email: d.email,
                        phone: d.phoneNumber,
                      },
                    ])
                  } else {
                    // If they have No-Fly-Zone violations listed, update time
                    setPilotsInViolation((prev) => {
                      const NewState = prev.map((o) => {
                        if (o.pilotId === d.pilotId) {
                          return {
                            ...o,
                            timeViolated: new Date().toISOString(),
                            distance: ""
                          }
                        }
                        return o
                      })
                      return NewState
                    })
                  }
                  // console.log(res.data)
                })
                .catch((err) => {
                  // Catch any errors that may have occurred during the API call
                  console.log(err)
                })
            }
          }
        })
        .catch((err) => {
          // Catch any errors that may have occurred during the API call
          console.log(err)
        })
    }, 2000) // 2 second intervalls for API calls
    // console.clear()
    // console.log(pilotsInViolation) // This is for testing purposes
    pilotManager()
    return () => clearInterval(interval)
  }, [pilotsInViolation])

  // TODO: Function to remove pilots who haven't violated the NFZ in the past 10 mins
  // This function checks whether pilot has NFZ violations in the past 10 mins
  const pilotManager = () => {
    // Checks whether it has been 10 mins since last violation
    // Go through each child in the object array
    pilotsInViolation.forEach((d) => {
      // Change to milliseconds that will be compared
      let vT = new Date(d.timeViolated).getTime()

      // test whether it has been a minute
      if ((Number(vT) + 600000) < new Date().getTime()) {

        // Filter out from the state
        const newPilotList = pilotsInViolation.filter(pilot => {
          return pilot.pilotId !== d.pilotId
        })

        // Set new state with the filtered pilots
        setPilotsInViolation([...newPilotList])
      }
    })
  }

  return (
    <div className="App">
      <h1> Reaktor Pre-Assignment</h1>
      {/* Display the radar */}
      {/* <Radar data={drones} /> */}

      {/* List of pilots who have violated the No-Fly-Zone in the past 10 minutes */}
      <ListView data={pilotsInViolation} />
    </div>
  )
}

export default App
