// @author: Ville Wilpola
// This file controls the list view

// imports styles
import './listView.scss'

const ListView = ({ data }) => {
  // return the list view element

  return (
    <div className="list-container">
      <div className="list-header">
        <h2>List of violators | {data.length} violators</h2>
      </div>

      {/* Sort the list in newest at the top */}
      {[...data]
        .sort(
          (a, b) =>
            new Date(b.timeViolated).getTime() -
            new Date(a.timeViolated).getTime(),
        )
        .map((pilot, key) => {
          return (
            <div className="list-item" key={key}>
              <h3>
                {pilot.firstName} {pilot.lastName}
              </h3>
              {`Closest distance from nest: ${pilot.distance ? pilot.distance : 'data missing'}`} <br />
              {`Time of Violation: ${new Date(
                pilot.timeViolated,
              ).toUTCString()}`}
            </div>
          )
        })}
    </div>
  )
}

export default ListView
